/* eslint-disable prettier/prettier */
const messages = {
  'menu.home': 'Главная',
  'menu.login': 'Вход',
  'menu.logout': 'Выход',
  'menu.routes': 'Маршруты',
  'menu.flights': 'Рейсы',
  'flight.departure': 'Отправление',
  'flight.noFlightsAvailable': 'Рейсов нет.',
  'flight.name': 'Название',
  'flight.departureDetails': 'Отправление',
  'flight.passengerInfo': 'Информация о пассажирах будет добавлена позже.',
  'passengers.status.active': 'Активный',
  'passengers.status.booked': 'Забронированный',
  'passengers.status.cancelled': 'Отмененный',
  'passengers.status.confirmed': 'Подтвержденный',
  'passengers.status.not_come': 'Не пришел',
  'passengers.status.error': 'Ошибка',
  'passengers.status.expired': 'Не оплачен вовремя',
  'passengers.status.landing': 'Посадка',
  'passengers.status.unknown': 'Неизвестный',
  'passengers.status.waiting_confirmation': 'Ожидает подтверждения',
  'passengers.paymentMethod': 'Тип оплаты',
  'passengers.payment.atlas_promocode': 'Промокод Атласа',
  'passengers.payment.card': 'Карта',
  'passengers.payment.cash': 'Наличные',
  'passengers.payment.miles': 'Мили',
  'passengers.payment.reccur': 'Рекуррентный платеж',
  'back': 'Назад',
};

type MessageKeys = keyof typeof messages;

interface iLocalizedTextProps {
  id: MessageKeys;
}

const LocalizedText: React.FC<iLocalizedTextProps> = ({ id }) => {
  return <span>{messages[id] || `Missing message: ${id}`}</span>;
};

export default LocalizedText;
