import { Navigate, Outlet } from 'react-router-dom';

import { usesAuthenticated } from '../../hooks';

function ProtectedRoutes() {
  const { isAuthenticated } = usesAuthenticated();

  return <>{isAuthenticated ? <Outlet /> : <Navigate to="/login" />}</>;
}

export default ProtectedRoutes;
