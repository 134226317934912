import { Card, Flex, Layout as AntdLayout } from 'antd';
import { Outlet } from 'react-router-dom';

const Layout = () => {
  return (
    <AntdLayout>
      <Flex style={{ minHeight: '100vh' }} justify="center" align="center">
        <Card bordered={false}>
          <Outlet />
        </Card>
      </Flex>
    </AntdLayout>
  );
};

export default Layout;
