import { List } from 'antd-mobile';

import LocalizedText from '../../components/LocalizedText';
import { Avatar } from '../../components/avatar';

import { AggregatedPassenger } from './utils';
import './styles.css';

interface PassengersListProps {
  header: string;
  aggregatedPassengers: AggregatedPassenger[];
  onClick: (aggregatedPassenger: AggregatedPassenger) => void;
}

export const PassengersList = ({
  header,
  aggregatedPassengers,
  onClick,
}: PassengersListProps) => {
  return (
    <List header={header}>
      {aggregatedPassengers.map((aggregatedPassenger, index) => {
        const { passenger, ticket, order, currencySymbol } =
          aggregatedPassenger;

        const description = (
          <div className="passenger-list__description">
            {order?.paymentMethod && (
              <div>
                <span>
                  {ticket.ticketPrice} {currencySymbol}
                </span>
                (
                <LocalizedText
                  id={`passengers.payment.${order.paymentMethod}`}
                />
                )
              </div>
            )}
            {ticket?.comment && <div>Комментарий: {ticket.comment}</div>}
          </div>
        );

        return (
          <List.Item
            key={passenger?.id || index}
            prefix={
              <Avatar
                firstName={passenger.firstName}
                lastName={passenger.lastName}
              />
            }
            extra={
              ticket?.status && (
                <LocalizedText id={`passengers.status.${ticket.status}`} />
              )
            }
            description={description}
            onClick={() => onClick(aggregatedPassenger)}
          >
            {passenger?.firstName} {passenger?.lastName}
          </List.Item>
        );
      })}
    </List>
  );
};
