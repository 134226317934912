import {
  Order,
  OrderWithTickets,
  Passenger,
  Ticket,
} from '../../__generated__/types';

export interface AggregatedPassenger {
  passenger: Passenger;
  ticket: Ticket;
  order: Order;
  currencySymbol: string;
}

export const aggregatePassengers = (
  arr: OrderWithTickets[],
): AggregatedPassenger[] =>
  arr
    .map((item) => {
      const tickets = (item?.tickets || []).map((t) => ({
        ...t,
        order: item?.order,
        currencySymbol: item.currencySymbol,
      }));

      return {
        ...item,
        tickets,
      };
    })
    .flatMap((item) => item?.tickets) as AggregatedPassenger[];
