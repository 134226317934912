import { useToken } from '../../hooks';
import { Loader } from '../../components/loader';

const Callback = () => {
  useToken();

  return (
    <div>
      <Loader />
    </div>
  );
};

export default Callback;
