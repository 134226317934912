import './styles.css';

interface AvatarProps {
  firstName: string;
  lastName: string;
  size?: number;
}

const colors = [
  '#1abc9c',
  '#2ecc71',
  '#3498db',
  '#9b59b6',
  '#34495e',
  '#16a085',
  '#27ae60',
  '#2980b9',
  '#8e44ad',
  '#2c3e50',
  '#f1c40f',
  '#e67e22',
  '#e74c3c',
  '#95a5a6',
  '#f39c12',
  '#d35400',
  '#c0392b',
  '#7f8c8d',
];

export const Avatar = ({ firstName, lastName, size = 32 }: AvatarProps) => {
  const colorIndex = firstName.charCodeAt(0) % colors.length;
  const color = colors[colorIndex - 1];
  const text = firstName[0] + lastName[0];

  return (
    <div
      className="avatar"
      // @ts-ignore
      style={{ backgroundColor: color, '--size': `${size}px` }}
    >
      {text}
    </div>
  );
};
