import { jwtDecode } from 'jwt-decode';
import { useState, useEffect } from 'react';

import { showError } from '../../../utils';

interface JWTPayload {
  amr: string[];
  at_hash: string;
  aud: string[];
  auth_time: number;
  email: string;
  email_verified: boolean;
  exp: number;
  family_name: string;
  given_name: string;
  iat: number;
  iss: string;
  jti: string;
  rat: number;
  sid: string;
  sub: string;
  updated_at: number;
}

export const useToken = () => {
  const [token, setToken] = useState<JWTPayload | null>(null);

  useEffect(() => {
    const tokenObjStr = localStorage.getItem('TOKEN_OBJ');
    if (tokenObjStr) {
      const tokenObj = JSON.parse(tokenObjStr);
      try {
        const decodedIdToken = jwtDecode<JWTPayload>(tokenObj.idToken);
        setToken(decodedIdToken); // Extracting email from idToken
      } catch (e) {
        showError(new Error('Выбирите "openId" и "email" при авторизации'));
      }
    }
  }, []);

  return token;
};
