import * as React from 'react';
import { FC } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { TabBar } from 'antd-mobile';
import { TruckOutline, UserOutline } from 'antd-mobile-icons';

import styles from './style.module.css';

const Bottom: FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { pathname } = location;

  const tabs = [
    {
      key: 'home',
      title: 'Рейсы',
      icon: <TruckOutline />,
      onClick: () => {
        navigate('');
      },
    },
    {
      key: 'message',
      title: 'Аккаунт',
      icon: <UserOutline />,
      onClick: () => {
        navigate('/account');
      },
    },
  ];

  return (
    <TabBar activeKey={pathname} style={{ backgroundColor: '#ffffff' }}>
      {tabs.map((item) => (
        <TabBar.Item
          key={item.key}
          icon={item.icon}
          title={item.title}
          onClick={item.onClick}
        />
      ))}
    </TabBar>
  );
};

const MobileLayout = () => {
  return (
    <div className={styles.app}>
      <div className={styles.body}>
        <Outlet />
      </div>
      <div className={styles.bottom}>
        <Bottom />
      </div>
    </div>
  );
};

const Layout = () => {
  return (
    <div className={styles.layout}>
      <div className={styles.layoutInner}>
        <MobileLayout />
      </div>
    </div>
  );
};
export default Layout;
