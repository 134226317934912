import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { v4 } from 'uuid';
import { Empty, List, Skeleton } from 'antd-mobile';

import { useGetAllFlights } from '../gql/__generated__/GetAllFlights';
import LocalizedText from '../../../components/LocalizedText';
import type { FlightFragment } from '../gql/__generated__/FlightFragment.fragment';
import { showError } from '../../../utils';
import { NavBar } from '../../../components/nav-bar';

import { useToken } from './useToken';

import './style.css';
import 'moment/locale/ru'; // Импорт русской локализации

moment.locale('ru'); // Установка локализации

const LABEL_YESTERDAY = 'Вчера';
const LABEL_TODAY = 'Сегодня';
const LABEL_TOMMOROW = 'Завтра';

function getSortWeight(dateLabel: string) {
  if (dateLabel === LABEL_YESTERDAY) return 1;
  if (dateLabel === LABEL_TODAY) return 2;
  if (dateLabel === LABEL_TOMMOROW) return 3;
  return 4; // Все остальные даты получают вес 4
}

const FlightsPage = () => {
  const navigate = useNavigate();
  const token = useToken();

  const { data, loading: flightsLoading } = useGetAllFlights({
    skip: !token?.email,
    variables: {
      req: { driverEmail: token?.email, statuses: [], flightIds: [] },
    },
    fetchPolicy: 'network-only',
    onError: showError,
  });

  const flights = data?.flights_getAll?.flights || [];

  const handleSelectEvent = useCallback((flight: FlightFragment) => {
    navigate(`/passengers/${flight.id}`);
  }, []);

  const today = moment().startOf('day');
  const twoDaysAgo = today.clone().subtract(2, 'days');
  const yesterday = today.clone().subtract(1, 'days');
  const tomorrow = today.clone().add(1, 'days');

  const flightsByDate = flights.reduce<Record<string, FlightFragment[]>>(
    (acc, flight) => {
      if (!flight || !flight.departureTime) return acc;
      const departure = moment(flight.departureTime);
      if (departure.isBefore(twoDaysAgo, 'day')) {
        return acc;
      }

      const key = departure.isSame(yesterday, 'day')
        ? LABEL_YESTERDAY
        : departure.isSame(today, 'day')
          ? LABEL_TODAY
          : departure.isSame(tomorrow, 'day')
            ? LABEL_TOMMOROW
            : departure.format('DD MMMM YYYY');

      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(flight);
      return acc;
    },
    {},
  );

  const empty = !Object.keys(flightsByDate).length;

  return (
    <div className="page-layout">
      <NavBar>Рейсы</NavBar>

      <div className="page-layout__body">
        {flightsLoading ? (
          <>
            <Skeleton.Title />
            <Skeleton.Paragraph />
            <Skeleton.Paragraph />
          </>
        ) : empty ? (
          <Empty description="Нет ближайших рейсов" />
        ) : (
          Object.entries(flightsByDate)
            .sort((a, b) => {
              const weightA = getSortWeight(a[0]);
              const weightB = getSortWeight(b[0]);

              if (weightA !== weightB) {
                return weightA - weightB;
              }

              // Если даты имеют одинаковый вес (все обычные даты), сравниваем как даты
              return moment(a[0], 'DD MMMM YYYY').diff(
                moment(b[0], 'DD MMMM YYYY'),
              );
            })
            .map(([date, flights]) => (
              <div key={date}>
                <div>
                  <List header={date}>
                    {flights.map((flight) => (
                      <List.Item
                        key={v4()}
                        onClick={() => handleSelectEvent(flight)}
                        description={
                          <>
                            <LocalizedText id="flight.departure" />:{' '}
                            {moment(flight.departureTime).format('LT')}
                          </>
                        }
                      >
                        {flight.name}
                      </List.Item>
                    ))}
                  </List>
                </div>
              </div>
            ))
        )}
      </div>
    </div>
  );
};

export default FlightsPage;
