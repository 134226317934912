import { useNavigate, useSearchParams } from 'react-router-dom';
import { useEffect } from 'react';

import { authService } from '../services/auth-service';

export const useLogin = () => {
  const login = () => {
    authService.login();
  };

  return { login };
};

export const useToken = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    const code = searchParams.get('code');

    if (!authService.checkIfLoggedIn()) {
      code &&
        authService
          .makeRefreshTokenRequest(code)
          .then(() => {
            navigate('/');
          })
          .catch((err) => {
            console.error(err);
          });
    }
  }, []);
};

export const useLogout = () => {
  const navigate = useNavigate();

  useEffect(() => {
    logout();
  }, []);

  const logout = async () => {
    try {
      await authService.logout();
    } catch (error) {
      console.error(error);
    }
    navigate('/login');
  };
};

export const usesAuthenticated = () => {
  const isAuthenticated = authService.checkIfLoggedIn();

  return { isAuthenticated };
};
