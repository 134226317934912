import {
  NavBar as AntdNavBar,
  NavBarProps as AntdNavBarProps,
} from 'antd-mobile';

interface NavBarProps extends AntdNavBarProps {
  children?: React.ReactNode;
}

export const NavBar = ({ children, ...props }: NavBarProps) => {
  return (
    <AntdNavBar
      style={{
        '--border-bottom': '1px #eee solid',
        '--height': '52px',
        backgroundColor: '#fff',
      }}
      backIcon={!!props.onBack}
      {...props}
    >
      {children}
    </AntdNavBar>
  );
};
