import { AutoCenter, Button } from 'antd-mobile';
import { useNavigate } from 'react-router-dom';

import { useToken } from '../flights/FlightsPage/useToken';
import { Avatar } from '../../components/avatar';
import { Typography } from 'antd';

const AccountPage = () => {
  const navigate = useNavigate();
  const token = useToken();
  const handleLogout = () => {
    navigate('/logout');
  };
  return (
    <div
      className="page-layout"
      style={{
        margin: '0 16px',
      }}
    >
      <div className="page-layout__body" style={{ padding: '16px 16px' }}>
        <AutoCenter>
          <Avatar firstName={String(token?.email)} lastName={' '} size={80} />
        </AutoCenter>
        <AutoCenter>
          <Typography.Title type="secondary" level={3}>
            {token?.email}
          </Typography.Title>
        </AutoCenter>
      </div>

      <div style={{ paddingBottom: 16 }}>
        <Button block color="danger" onClick={handleLogout}>
          Выйти
        </Button>
      </div>
    </div>
  );
};

export default AccountPage;
