import { AuthorizationRequest } from '@openid/appauth/built/authorization_request';
import {
  AuthorizationRequestHandler,
  AuthorizationRequestResponse,
} from '@openid/appauth/built/authorization_request_handler';
import { AuthorizationServiceConfiguration } from '@openid/appauth/built/authorization_service_configuration';
import {
  GRANT_TYPE_AUTHORIZATION_CODE,
  GRANT_TYPE_REFRESH_TOKEN,
  TokenRequest,
} from '@openid/appauth/built/token_request';
import {
  BaseTokenRequestHandler,
  TokenRequestHandler,
} from '@openid/appauth/built/token_request_handler';
import {
  BasicQueryStringUtils,
  DefaultCrypto,
  Requestor,
  TokenResponse,
} from '@openid/appauth';
import { Configuration, FrontendApi } from '@ory/client';

import { OAUTH_CONFIG, TOKEN_OBJ, CODE_VERIFIER } from '../constants';

export interface AuthService {
  checkIfLoggedIn(): boolean;
  login(): Promise<boolean>;
  logout(): void;
}

class MyAuthorizationRequestHandler extends AuthorizationRequestHandler {
  constructor() {
    super(new BasicQueryStringUtils(), new DefaultCrypto());
  }

  performAuthorizationRequest(
    configuration: AuthorizationServiceConfiguration,
    request: AuthorizationRequest,
  ) {
    request
      .setupCodeVerifier()
      .then(() => {
        const url = this.buildRequestUrl(configuration, request);
        console.log('Making a request to ', url);
        request.internal?.code_verifier &&
          localStorage.setItem(CODE_VERIFIER, request.internal.code_verifier);
        window.location.href = this.buildRequestUrl(configuration, request);
      })
      .catch((error) => {
        console.log('Something bad happened ', error);
      });
  }

  protected completeAuthorizationRequest(): Promise<AuthorizationRequestResponse | null> {
    return Promise.resolve(null);
  }
}

class FetchRequestor extends Requestor {
  xhr({ url, method, headers, data }: Record<string, any>) {
    return fetch(url, { headers, method, body: data }).then((response) =>
      response.json(),
    );
  }
}

const requestor = new FetchRequestor();

const openIdConnectUrl = process.env.REACT_APP_OPEN_ID_CONNECT_URL || '';
const clientId = process.env.REACT_APP_CLIENT_ID || '';
const redirectUri = process.env.REACT_APP_ORY_REDIRECT_URL || '';
const scope = 'openid profile email offline_access';

class Auth implements AuthService {
  private authorizationHandler: AuthorizationRequestHandler;
  private tokenHandler: TokenRequestHandler;
  private oryConnection: FrontendApi;
  private refreshToken: string | undefined;
  private accessTokenResponse: TokenResponse | undefined;
  static _instance: Auth;

  private constructor() {
    this.authorizationHandler = new MyAuthorizationRequestHandler();
    this.tokenHandler = new BaseTokenRequestHandler(requestor);
    this.oryConnection = new FrontendApi(
      new Configuration({
        basePath: process.env.REACT_APP_ORY_PROFILE_URL,
        baseOptions: {
          withCredentials: true,
        },
      }),
    );
  }

  public static get instance(): Auth {
    if (!Auth._instance) {
      Auth._instance = new Auth();
    }

    return Auth._instance;
  }

  async login() {
    await this.fetchServiceConfiguration();
    await this.makeAuthorizationRequest();
    return true;
  }

  checkIfLoggedIn() {
    const tokenObjStr = localStorage.getItem(TOKEN_OBJ);
    if (tokenObjStr) {
      const tokenObj = JSON.parse(tokenObjStr);
      this.accessTokenResponse = new TokenResponse(tokenObj);
    }

    return !!this.accessTokenResponse && this.accessTokenResponse.isValid();
  }

  async logout() {
    localStorage.removeItem(TOKEN_OBJ);
    this.accessTokenResponse = undefined;
    const { data: flow } = await this.oryConnection.createBrowserLogoutFlow();
    await this.oryConnection.updateLogoutFlow({
      token: flow.logout_token,
    });
  }

  fetchServiceConfiguration() {
    return AuthorizationServiceConfiguration.fetchFromIssuer(
      openIdConnectUrl,
      requestor,
    ).then((response) => {
      localStorage.setItem(OAUTH_CONFIG, JSON.stringify(response));
      return response;
    });
  }

  makeAuthorizationRequest() {
    const configurationStr = localStorage.getItem(OAUTH_CONFIG);
    const configuration = configurationStr && JSON.parse(configurationStr);

    if (!configuration) {
      console.log('Unknown service configuration');
      return;
    }

    const extras = { prompt: 'consent', access_type: 'offline' };

    const request = new AuthorizationRequest({
      client_id: clientId,
      redirect_uri: redirectUri,
      scope: scope,
      response_type: AuthorizationRequest.RESPONSE_TYPE_CODE,
      state: undefined,
      extras: extras,
    });

    console.log('Making authorization request ', configuration, request);
    this.authorizationHandler.performAuthorizationRequest(
      configuration,
      request,
    );
  }

  makeRefreshTokenRequest(code: string) {
    const configurationStr = localStorage.getItem(OAUTH_CONFIG);
    const configuration = configurationStr && JSON.parse(configurationStr);
    if (!configuration) {
      console.log('Unknown service configuration');
      return Promise.resolve();
    }

    const extras: Record<string, string> = {};

    const codeVerifier = localStorage.getItem(CODE_VERIFIER);

    if (codeVerifier) {
      extras.code_verifier = codeVerifier;
    }

    const request = new TokenRequest({
      client_id: clientId,
      redirect_uri: redirectUri,
      grant_type: GRANT_TYPE_AUTHORIZATION_CODE,
      code: code,
      refresh_token: undefined,
      extras: extras,
    });

    return this.tokenHandler
      .performTokenRequest(configuration, request)
      .then((response) => {
        console.log(`Refresh Token is ${response.refreshToken}`);
        this.refreshToken = response.refreshToken;
        this.accessTokenResponse = response;
        localStorage.setItem(TOKEN_OBJ, JSON.stringify(response));
        return response;
      });
  }

  performWithFreshTokens() {
    const configurationStr = localStorage.getItem(OAUTH_CONFIG);
    const configuration = configurationStr && JSON.parse(configurationStr);
    if (!configuration) {
      console.log('Unknown service configuration');
      return Promise.reject('Unknown service configuration');
    }
    if (!this.refreshToken) {
      console.log('Missing refreshToken.');
      return Promise.resolve('Missing refreshToken.');
    }
    if (this.accessTokenResponse && this.accessTokenResponse.isValid()) {
      return Promise.resolve(this.accessTokenResponse.accessToken);
    }
    const request = new TokenRequest({
      client_id: clientId,
      redirect_uri: redirectUri,
      grant_type: GRANT_TYPE_REFRESH_TOKEN,
      code: undefined,
      refresh_token: this.refreshToken,
      extras: undefined,
    });

    return this.tokenHandler
      .performTokenRequest(configuration, request)
      .then((response) => {
        this.accessTokenResponse = response;
        localStorage.setItem(TOKEN_OBJ, JSON.stringify(response));
        return response.accessToken;
      });
  }
}

export const authService = Auth.instance;
