import { Button } from 'antd';

import { useLogin } from '../../hooks';

const Auth = () => {
  const { login } = useLogin();

  return (
    <div>
      <h1>Authentication</h1>
      <Button onClick={login}>Login</Button>
    </div>
  );
};

export default Auth;
