import { Row, Spin, SpinProps } from 'antd';

interface LoaderProps extends SpinProps {
  children?: React.ReactNode;
}

export const Loader = ({ children, size }: LoaderProps) => {
  return (
    <Row justify="center">
      <Spin size={size}>{children}</Spin>
    </Row>
  );
};
