// 🛑 NOTICE: __generated__ folders should be added to .gitignore
import * as Types from '../../../../__generated__/types';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ChangeStatusVariables = Types.Exact<{
  req?: Types.InputMaybe<Types.ChangeStatusByDriverRequest_Input>;
}>;


export type ChangeStatus = { __typename: 'Mutation', tickets_changeStatusByDriver?: { __typename: 'ChangeStatusByDriverResponse', ticket: { __typename: 'Ticket', id: string, status: Types.EOrderStatus } } | null };


export const ChangeStatusDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"ChangeStatus"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"req"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"ChangeStatusByDriverRequest_Input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"tickets_changeStatusByDriver"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"req"},"value":{"kind":"Variable","name":{"kind":"Name","value":"req"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"ticket"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"status"}}]}}]}}]}}]} as unknown as DocumentNode;
export type ChangeStatusMutationFn = Apollo.MutationFunction<ChangeStatus, ChangeStatusVariables>;

/**
 * __useChangeStatus__
 *
 * To run a mutation, you first call `useChangeStatus` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeStatus` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeStatus, { data, loading, error }] = useChangeStatus({
 *   variables: {
 *      req: // value for 'req'
 *   },
 * });
 */
export function useChangeStatus(baseOptions?: Apollo.MutationHookOptions<ChangeStatus, ChangeStatusVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangeStatus, ChangeStatusVariables>(ChangeStatusDocument, options);
      }
export type ChangeStatusHookResult = ReturnType<typeof useChangeStatus>;
export type ChangeStatusMutationResult = Apollo.MutationResult<ChangeStatus>;
export type ChangeStatusMutationOptions = Apollo.BaseMutationOptions<ChangeStatus, ChangeStatusVariables>;